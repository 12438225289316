.adminPanel-area{
    background: url(../../images/contact/1.jpg) center center/cover no-repeat local;
    position: relative;
    background-attachment: fixed;
    padding: 0px;
    &::before{
     /*    content: "";
        background-color: rgba(21, 26, 48, 0.8); */
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .contactInfo{
        @media (max-width:768px) {
            margin-bottom: 40px;
        }
        span{
            font-size: 16px;
            color: #ada282;
        }
        h2{
            font-size: 36px;
            margin-bottom: 20px;
            margin-top: 10px;
            color: #fff;
            position: relative;
            display: inline-block;
            &::before{
                content: "";
                position: absolute;
                right: -68px;
                top: 25px;
                width: 60px;
                height: 1px;
                background: #c0b596;
                @media (max-width:1200px) {
                    width: 30px;
                    left: -35px;
                }
            }
        }
        h6{
            font-size: 18px;
            color: #c0b596;
            font-family: 'Open Sans', sans-serif;
            @media (max-width:1200px) {
                font-size: 16px;
            }
        }
        p{
            margin-top: 30px;
            color: #ddd;
            line-height: 30px;
        }
    }
}


.btn-menu {
	display: none;
	padding: 20px;
	background: #0d2c44;
	color:#fff;
}

.btn-menu .icono {
	float: right;
}

.contenedor-menu {
	width: 100%;
/* 	min-width: 300px; */
background-color: white !important;
	display: inline-block;

	line-height: 18px;
}

.contenedor-menu .menu {
    width: 100%;
    font-size: 16px !important;
}
.contenedor-menu .menu  a{
    border-left: 4px solid white !important;
}
.contenedor-menu ul {
	list-style: none;
}

.contenedor-menu .menu li a {
	color:#494949;
	display: block;
	padding: 15px 20px;
	background: white;
}

.contenedor-menu .menu li a:hover {
	background: rgba(34,36,38,.1) !important;
    color: #2e282e;
    border-left: 4px solid #2e282e !important;
}
.contenedor-menu .menu li a:active {
	background: rgba(34,36,38,.1) !important;
    color: #2e282e;
    border-left: 4px solid #2e282e !important;
}
.contenedor-menu .menu .icono {
	font-size: 14px !important;
	line-height: 18px;
}

.contenedor-menu .menu .icono.izquierda {
	float: left;
	margin-right: 10px; 
}

.contenedor-menu .menu .icono.derecha {
	float: right;
	margin-left: 10px; 
}

.contenedor-menu .menu ul {
	display: none;
}

.contenedor-menu .menu ul li a {
	background: #f9fafb;
    color:#2e282e;
    padding-left: 22px !important;
}

.contenedor-menu .menu .activado > a {
	background: rgb(212, 212, 212);
    columns: #2e282e;
    border-left: 4px solid #2e282e !important;
}
 
/* @media screen and (max-width: 50px) {
	body {
		padding-top: 80px;
	}

	.contenedor-menu {
		margin: 0;
		width: 100%;
		position: fixed;
		top:0;
		z-index: 1000;
	}

	.btn-menu {
		display: block;
	}

	.contenedor-menu .menu {
		display: none;
	}
}  */

.receipnest-top-area{
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.receipnest-top-area-by-fill-receipnest{
    width: 140px;
    background-color: #2e282e;
    color: white;
    border-radius: 10px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    cursor: pointer;
}
.receipnest-top-area-by-fill-receipnest-ipad{
    width: 140px;
    background-color: white;
    color: #2e282e;
    border-radius: 10px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    border: 1px solid grey;
    cursor: pointer;
}
.receipnest-top-area-file-bottom-area{
    margin:  0px 20px 20px 20px;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
}
.admin-cutomer-REcepnest-files-rea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 20px 5px 20px;
}
.receipnest-file-customer-files-area655{
    margin: 20px 0px 0px 10px;
    border: 1px solid lightgrey;
    background-color: white;
    height: 100%;
  
}
.receipnest-file-customer-files-area{
    padding: 20px 0px 0px 15px;
}
.loader-for-tabel-invoce{background-color:#fff}.loader-for-tabel-invoce,.loader-for-tabel-invoce:after{position:relative;height:300px;display:flex;justify-content:center;align-items:center}.loader-for-tabel-invoce:after{background:url(https://react.semantic-ui.com/images/wireframe/paragraph.png) 0 no-repeat local;background-color:#fff;opacity:.2}.loader1188{border-radius:50%;border:10px solid #f3f3f3;border-top-color:#302e34;width:60px!important;height:60px!important;-webkit-animation:spin 1s linear infinite;animation:spin 1s linear infinite;opacity:1!important}@-webkit-keyframes spin{0%{-webkit-transform:rotate(0deg)}to{-webkit-transform:rotate(1turn)}}@keyframes spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}