	/*
  	Flaticon icon font: Flaticon
  	Creation date: 28/04/2020 10:52
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 60px;
font-style: normal;
margin-left: 10px;
}

.flaticon-value:before { content: "\f100"; }
.flaticon-global:before { content: "\f101"; }
.flaticon-target:before { content: "\f102"; }
.flaticon-public-relation:before { content: "\f103"; }
.flaticon-focus:before { content: "\f104"; }
.flaticon-newspaper:before { content: "\f105"; }
.flaticon-dollar:before { content: "\f106"; }
.flaticon-affiliate:before { content: "\f107"; }
.flaticon-premium:before { content: "\f108"; }
.flaticon-newspaper-1:before { content: "\f109"; }
.flaticon-marketing:before { content: "\f10a"; }
.flaticon-report:before { content: "\f10b"; }
.flaticon-smartphone:before { content: "\f10c"; }
.flaticon-finances:before { content: "\f10d"; }
.flaticon-investment:before { content: "\f10e"; }
.flaticon-atm:before { content: "\f10f"; }
.flaticon-money:before { content: "\f110"; }
.flaticon-bank:before { content: "\f111"; }
.flaticon-worker:before { content: "\f112"; }
.flaticon-laborers:before { content: "\f113"; }
.flaticon-medal:before { content: "\f114"; }
.flaticon-trophy:before { content: "\f115"; }
.flaticon-medal-1:before { content: "\f116"; }
.flaticon-law:before { content: "\f117"; }
.flaticon-judge:before { content: "\f118"; }
.flaticon-band-1:before { content: "\f119"; }
.flaticon-information:before { content: "\f11a"; }
.flaticon-intellectual:before { content: "\f11b"; }
.flaticon-intellectual-1:before { content: "\f11c"; }
.flaticon-intellectual-property:before { content: "\f11d"; }
.flaticon-folders:before { content: "\f11e"; }
.flaticon-family:before { content: "\f11f"; }
.flaticon-home:before { content: "\f120"; }
.flaticon-family-1:before { content: "\f121"; }
.flaticon-account:before { content: "\f122"; }
.flaticon-folder:before { content: "\f123"; }
.flaticon-private:before { content: "\f124"; }
.flaticon-profile:before { content: "\f125"; }
.flaticon-pet-insurance:before { content: "\f126"; }
.flaticon-term:before { content: "\f127"; }
.flaticon-life-insurance:before { content: "\f128"; }
.flaticon-worker-1:before { content: "\f129"; }
.flaticon-key:before { content: "\f12a"; }
.flaticon-car-insurance:before { content: "\f12b"; }
.flaticon-market:before { content: "\f12c"; }
.flaticon-capitalization:before { content: "\f12d"; }
.flaticon-money-1:before { content: "\f12e"; }
.flaticon-handshake:before { content: "\f12f"; }
.flaticon-injury:before { content: "\f130"; }
.flaticon-personal:before { content: "\f131"; }
.flaticon-injured:before { content: "\f132"; }
.flaticon-auction:before { content: "\f133"; }
.flaticon-law-2:before { content: "\f134"; }
.flaticon-auction-1:before { content: "\f135"; }
.flaticon-avatar:before { content: "\f136"; }
.flaticon-book:before { content: "\f137"; }
.flaticon-taxes:before { content: "\f138"; }
.flaticon-tax:before { content: "\f139"; }
.flaticon-tax-1:before { content: "\f13a"; }
.flaticon-tax-2:before { content: "\f13b"; }
.flaticon-tax-3:before { content: "\f13c"; }
.flaticon-dashboard:before { content: "\f13d"; }
.flaticon-project-management:before { content: "\f13e"; }
.flaticon-home-1:before { content: "\f13f"; }
.flaticon-house:before { content: "\f140"; }
.flaticon-address:before { content: "\f141"; }
.flaticon-robber:before { content: "\f142"; }
.flaticon-thief:before { content: "\f143"; }
.flaticon-burglar:before { content: "\f144"; }
.flaticon-hacker:before { content: "\f145"; }
.flaticon-wounded:before { content: "\f146"; }
.flaticon-scratch:before { content: "\f147"; }
.flaticon-mortarboard:before { content: "\f148"; }
.flaticon-mortarboard-1:before { content: "\f149"; }
.flaticon-student:before { content: "\f14a"; }
.flaticon-graduation-cap:before { content: "\f14b"; }
.flaticon-collaboration:before { content: "\f14c"; }
.flaticon-employees:before { content: "\f14d"; }
.flaticon-employee:before { content: "\f14e"; }
.flaticon-process:before { content: "\f14f"; }
.flaticon-team:before { content: "\f150"; }
.flaticon-employee-1:before { content: "\f151"; }
.flaticon-work:before { content: "\f152"; }
.flaticon-search:before { content: "\f153"; }
.flaticon-employee-2:before { content: "\f154"; }
.flaticon-kpi:before { content: "\f155"; }
.flaticon-employee-3:before { content: "\f156"; }
.flaticon-edit:before { content: "\f157"; }
.flaticon-hired:before { content: "\f158"; }
.flaticon-skills:before { content: "\f159"; }
.flaticon-unknown:before { content: "\f15a"; }
.flaticon-balance:before { content: "\f15b"; }
.flaticon-balance-1:before { content: "\f15c"; }
.flaticon-auction-2:before { content: "\f15d"; }
.flaticon-auction-3:before { content: "\f15e"; }
.flaticon-legal-paper:before { content: "\f15f"; }
.flaticon-law-3:before { content: "\f160"; }
.flaticon-fair:before { content: "\f161"; }
.flaticon-balance-2:before { content: "\f162"; }
.flaticon-balance-scale:before { content: "\f163"; }
.flaticon-certificate:before { content: "\f164"; }
.flaticon-gdpr:before { content: "\f165"; }
.flaticon-hammer:before { content: "\f166"; }
.flaticon-policeman:before { content: "\f167"; }
.flaticon-soldier:before { content: "\f168"; }
.flaticon-handcuffs:before { content: "\f169"; }
.flaticon-justice:before { content: "\f16a"; }
.flaticon-thief-1:before { content: "\f16b"; }
.flaticon-law-5:before { content: "\f16c"; }
.flaticon-veredict:before { content: "\f16d"; }
.flaticon-prison:before { content: "\f16e"; }
.flaticon-promotion:before { content: "\f16f"; }
.flaticon-balance-3:before { content: "\f170"; }
.flaticon-book-1:before { content: "\f171"; }
.flaticon-responsibility:before { content: "\f172"; }
.flaticon-teamwork:before { content: "\f173"; }
.flaticon-optimization:before { content: "\f174"; }
.flaticon-brokerage:before { content: "\f175"; }
.flaticon-bank-1:before { content: "\f176"; }
.flaticon-business:before { content: "\f177"; }
.flaticon-corporate:before { content: "\f178"; }
.flaticon-outsorcing:before { content: "\f179"; }
.flaticon-stockholder:before { content: "\f17a"; }
.flaticon-organization:before { content: "\f17b"; }
.flaticon-corporate-1:before { content: "\f17c"; }
.flaticon-teamwork-1:before { content: "\f17d"; }
.flaticon-corporation:before { content: "\f17e"; }
.flaticon-team-2:before { content: "\f17f"; }
.flaticon-corporate-strategy:before { content: "\f180"; }
.flaticon-partner:before { content: "\f181"; }
.flaticon-presentation:before { content: "\f182"; }
.flaticon-lawyer:before { content: "\f183"; }
.flaticon-case:before { content: "\f184"; }
.flaticon-inequality:before { content: "\f185"; }
.flaticon-law-6:before { content: "\f186"; }
.flaticon-subpoena:before { content: "\f187"; }
.flaticon-court:before { content: "\f188"; }
.flaticon-lawyer-1:before { content: "\f189"; }
.flaticon-lawyer-2:before { content: "\f18a"; }
.flaticon-lawyer-3:before { content: "\f18b"; }
.flaticon-lawyer-4:before { content: "\f18c"; }
.flaticon-jury:before { content: "\f18d"; }
.flaticon-lawyer-5:before { content: "\f18e"; }
.flaticon-lawyer-6:before { content: "\f18f"; }
.flaticon-case-1:before { content: "\f190"; }
.flaticon-around:before { content: "\f191"; }
.flaticon-distribution:before { content: "\f192"; }
.flaticon-international:before { content: "\f193"; }
.flaticon-globe:before { content: "\f194"; }
.flaticon-worldwide:before { content: "\f195"; }
.flaticon-globalization:before { content: "\f196"; }
.flaticon-globe-1:before { content: "\f197"; }
.flaticon-international-1:before { content: "\f198"; }
.flaticon-international-2:before { content: "\f199"; }
.flaticon-balance-4:before { content: "\f19a"; }
.flaticon-cash-flow:before { content: "\f19b"; }
.flaticon-financial-profit:before { content: "\f19c"; }
.flaticon-funds:before { content: "\f19d"; }
.flaticon-bankruptcy:before { content: "\f19e"; }
.flaticon-financial:before { content: "\f19f"; }
.flaticon-income:before { content: "\f1a0"; }
.flaticon-central:before { content: "\f1a1"; }
.flaticon-financial-1:before { content: "\f1a2"; }
.flaticon-liability:before { content: "\f1a3"; }
.flaticon-certified:before { content: "\f1a4"; }
.flaticon-investment-1:before { content: "\f1a5"; }
.flaticon-global-1:before { content: "\f1a6"; }
.flaticon-grant:before { content: "\f1a7"; }
.flaticon-management:before { content: "\f1a8"; }
.flaticon-leverage:before { content: "\f1a9"; }
.flaticon-asset:before { content: "\f1aa"; }
.flaticon-financial-2:before { content: "\f1ab"; }
.flaticon-payment:before { content: "\f1ac"; }
.flaticon-financial-3:before { content: "\f1ad"; }
.flaticon-financial-4:before { content: "\f1ae"; }
.flaticon-business-1:before { content: "\f1af"; }
.flaticon-financial-5:before { content: "\f1b0"; }
.flaticon-insurance:before { content: "\f1b1"; }
.flaticon-family-2:before { content: "\f1b2"; }
.flaticon-paper:before { content: "\f1b3"; }
.flaticon-family-3:before { content: "\f1b4"; }
.flaticon-insurance-1:before { content: "\f1b5"; }
.flaticon-health-insurance:before { content: "\f1b6"; }
.flaticon-insurance-2:before { content: "\f1b7"; }
.flaticon-family-4:before { content: "\f1b8"; }
.flaticon-insurance-3:before { content: "\f1b9"; }
.flaticon-flower-shop:before { content: "\f1ba"; }
.flaticon-bitcoin-symbol-in-a-speech-bubble:before { content: "\f1bb"; }
.flaticon-market-1:before { content: "\f1bc"; }
.flaticon-purchase-order:before { content: "\f1bd"; }
.flaticon-logistics-delivery:before { content: "\f1be"; }
.flaticon-billboard:before { content: "\f1bf"; }
.flaticon-billboard-1:before { content: "\f1c0"; }
.flaticon-sales:before { content: "\f1c1"; }
.flaticon-global-2:before { content: "\f1c2"; }
.flaticon-discount:before { content: "\f1c3"; }
.flaticon-scale:before { content: "\f1c4"; }
.flaticon-online-shopping:before { content: "\f1c5"; }
.flaticon-exchange-rate:before { content: "\f1c6"; }
.flaticon-money-transfer:before { content: "\f1c7"; }
.flaticon-online-shopping-2:before { content: "\f1c8"; }
.flaticon-money-transfer-1:before { content: "\f1c9"; }
.flaticon-laborers-1:before { content: "\f1ca"; }
.flaticon-professional:before { content: "\f1cb"; }
.flaticon-worker-2:before { content: "\f1cc"; }
.flaticon-secret-message:before { content: "\f1cd"; }
.flaticon-classified:before { content: "\f1ce"; }
.flaticon-accounting:before { content: "\f1cf"; }
.flaticon-secret-file:before { content: "\f1d0"; }
.flaticon-resume:before { content: "\f1d1"; }
.flaticon-confidential:before { content: "\f1d2"; }
.flaticon-confidentiality:before { content: "\f1d3"; }
.flaticon-personal-information:before { content: "\f1d4"; }
.flaticon-teamwork-2:before { content: "\f1d5"; }
.flaticon-private-1:before { content: "\f1d6"; }
.flaticon-vpn:before { content: "\f1d7"; }
.flaticon-confidential-folder:before { content: "\f1d8"; }
.flaticon-private-network:before { content: "\f1d9"; }
.flaticon-life-insurance-1:before { content: "\f1da"; }
.flaticon-shield:before { content: "\f1db"; }
.flaticon-house-1:before { content: "\f1dc"; }
.flaticon-health-insurance-1:before { content: "\f1dd"; }
.flaticon-insurance-4:before { content: "\f1de"; }
.flaticon-insurance-5:before { content: "\f1df"; }
.flaticon-worker-3:before { content: "\f1e0"; }
.flaticon-insurance-6:before { content: "\f1e1"; }
.flaticon-support:before { content: "\f1e2"; }
.flaticon-conversation:before { content: "\f1e3"; }
.flaticon-customer-support:before { content: "\f1e4"; }
.flaticon-support-1:before { content: "\f1e5"; }
.flaticon-customer-service:before { content: "\f1e6"; }
.flaticon-chemistry:before { content: "\f1e7"; }
.flaticon-scientist:before { content: "\f1e8"; }
.flaticon-bullhorn:before { content: "\f1e9"; }
.flaticon-petroleum:before { content: "\f1ea"; }
.flaticon-gas:before { content: "\f1eb"; }
.flaticon-truck:before { content: "\f1ec"; }
.flaticon-startup:before { content: "\f1ed"; }
.flaticon-planet:before { content: "\f1ee"; }
.flaticon-planet-earth:before { content: "\f1ef"; }