.le-frame__content__body__main {
  margin-top: 24px; }
  .le-frame__content__body__main h3 {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 40px;
    color: #c0b596; }
  .le-frame__content__body__main .newsletterForm {
    position: relative; }

.description_of_card_of_invoicetable {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 70px;
  font-size: 600;
  color: #8d8c8c; }

@media (max-width: 7000px) and (min-width: 1120px) {
  .description_of_card_of_invoicetable_area {
    width: 90%; } }

.class-table-particular {
  width: 500px !important; }

.invoice-le-view_area {
  align-self: center !important; }
  .invoice-le-view_area .new-invoice-header-text-area {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-radius: 8px;
    border: 1px solid transparent;
    margin-bottom: 24px;
    background: #f3fbff;
    border-color: #dff4ff; }
    .invoice-le-view_area .new-invoice-header-text-area .new-invoice-header-text-area::before {
      background: #007197; }
    @media (min-width: 480px) {
      .invoice-le-view_area .new-invoice-header-text-area h2 {
        font-size: 33px; } }
    .invoice-le-view_area .new-invoice-header-text-area h2 {
      margin: 0;
      font-size: 26px;
      line-height: 1.4;
      font-family: 'Open Sans', sans-serif; }
    .invoice-le-view_area .new-invoice-header-text-area .two-button-edi-save {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
      align-items: center;
      margin: -4px;
      -webkit-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-align-self: flex-start;
      align-self: flex-start;
      margin-top: 0; }
    .invoice-le-view_area .new-invoice-header-text-area Button {
      border-radius: 20px;
      padding: 8px 20px;
      font-size: 16px;
      text-align: center;
      min-width: 100px;
      border-radius: 500px;
      margin: 0;
      display: inline-block;
      box-sizing: border-box;
      vertical-align: middle;
      outline: 0;
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      cursor: pointer;
      text-decoration: none;
      -webkit-appearance: none; }
    .invoice-le-view_area .new-invoice-header-text-area .le-edit-button {
      margin: 4px;
      padding: 8px 35px; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions > .le-button, .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary > .le-button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
      padding-right: 16px;
      margin-right: 0; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--submit, .invoice-le-view_area .new-invoice-header-text-area .le-button--primary, .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary .le-button {
      color: #fff;
      background: #136acd;
      border: 1px solid transparent; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary .le-dropdown {
      float: right; }
    .invoice-le-view_area .new-invoice-header-text-area .le-dropdown {
      position: relative;
      display: inline-block; }
    .invoice-le-view_area .new-invoice-header-text-area [class*="le-button--with-actions"] .le-dropdown__toggle {
      height: 100%; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary .le-dropdown__toggle {
      border-left-color: rgba(255, 255, 255, 0.5); }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions .le-dropdown__toggle, .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary .le-dropdown__toggle {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 8px 0 8px;
      width: 36px;
      min-width: 0;
      border-left: 1px solid; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary .le-button {
      color: #fff;
      background: #136acd;
      border: 1px solid transparent; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button--with-actions--primary .le-dropdown__toggle.le-button .le-svg-icon {
      margin-right: 2px; }
    .invoice-le-view_area .new-invoice-header-text-area .le-dropdown__toggle.le-button .le-svg-icon {
      position: relative;
      bottom: -4px;
      margin-right: -5px; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button .le-svg-icon {
      margin-top: -1px;
      margin-bottom: -1px;
      position: relative;
      bottom: -3px; }
    .invoice-le-view_area .new-invoice-header-text-area .le-button {
      line-height: 1.4;
      font-size: 16px; }
    .invoice-le-view_area .new-invoice-header-text-area .is-open > .le-dropdown__menu--right {
      visibility: visible;
      height: auto;
      -webkit-animation: panel-slide-down .1s ease-in-out forwards;
      animation: panel-slide-down .1s ease-in-out forwards; }
    .invoice-le-view_area .new-invoice-header-text-area .le-dropdown__menu--right > li {
      display: block; }
    .invoice-le-view_area .new-invoice-header-text-area .le-dropdown__menu__item {
      position: relative;
      vertical-align: middle; }
    .invoice-le-view_area .new-invoice-header-text-area .le-dropdown__menu--right {
      list-style: none;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      text-align: left;
      display: block;
      visibility: hidden;
      position: absolute;
      top: 110%;
      z-index: 1000;
      min-width: 100%;
      padding: 8px 0;
      border-radius: 4px;
      color: #1c252c;
      background-color: white;
      box-shadow: 0 0 0 1px rgba(77, 101, 117, 0.1), 0 3px 10px 0 rgba(77, 101, 117, 0.2);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      height: 0;
      -webkit-transform: translateY(4px);
      transform: translateY(4px);
      overflow: hidden; }

@keyframes panel-side-down {
  0%, 30% {
    margin-top: -8px;
    opacity: 0; }
  100% {
    margin-top: 0;
    opacity: 1; } }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info {
    background: #f3fbff !important;
    border-color: #dff4ff !important; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info {
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 100%;
    border-radius: 8px;
    border: 1px solid transparent;
    margin-bottom: 24px; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    background: #007197; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info1 {
    background: #FFF8F8 !important;
    border-color: #FFF8F8 !important; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info1 {
    display: -webkit-flex;
    display: flex;
    position: relative;
    width: 100%;
    border-radius: 8px;
    border: 1px solid transparent;
    margin-bottom: 24px; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-radius: 8px 0 0 8px;
    background: #b52626; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify__icon-holder {
    padding: 16px 0 0 16px; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info .le-icon, .invoice-le-view_area .invoice-view__topmessage .le-notify--info .le-svg-icon {
    color: #007197; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info1 .le-icon, .invoice-le-view_area .invoice-view__topmessage .le-notify--info1 .le-svg-icon {
    color: #b52626; }
  .invoice-le-view_area .invoice-view__topmessage .le-svg-icon {
    width: 20px;
    height: 20px; }
  .invoice-le-view_area .invoice-view__topmessage .le-svg-icon {
    fill: currentColor; }
  .invoice-le-view_area .invoice-view__topmessage svg:not(:root) {
    overflow: hidden; }
  .invoice-le-view_area .invoice-view__topmessage :not(svg) {
    transform-origin: 0px 0px; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info .le-svg-icon {
    color: #007197; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify--info1 .le-svg-icon {
    color: #b52626; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify__content-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1;
    flex: 1;
    padding: 16px 0 0 8px;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify__content {
    -webkit-flex: 1;
    flex: 1;
    margin: 0 24px 16px 0;
    text-align: left;
    overflow-wrap: break-word;
    word-wrap: break-word; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify__message > :last-child {
    margin-bottom: 0; }
  .invoice-le-view_area .invoice-view__topmessage .le-notify__message > :first-child {
    margin-top: 0; }
  .invoice-le-view_area .invoice-preview__warning-message {
    text-align: center;
    margin-top: 40px;
    margin-bottom: -10px; }
  .invoice-le-view_area .le-text, .invoice-le-view_area .le-text--body {
    margin: 16px 0; }
  .invoice-le-view_area .invoice-previe-div-body-le {
    display: flex;
    justify-content: space-between;
    margin: 24px auto;
    width: 950px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    /* description news cards */ }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside {
      font-size: 14px;
      position: relative;
      padding: 10px;
      min-height: 1024px;
      box-sizing: border-box;
      border: 1px solid transparent;
      background: #fff;
      display: flex;
      flex-direction: column; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside {
      font-family: 'Open Sans', sans-serif; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header {
      display: flex;
      margin: 0 !important;
      justify-content: space-between; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header__logo {
      text-align: right;
      margin-top: 70px;
      margin-right: 45px; }
    .invoice-le-view_area .invoice-previe-div-body-le .logo-txt-setion {
      display: flex;
      justify-content: space-between; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header__logo img {
      max-height: 150px;
      max-width: 200px; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__header__info {
      justify-content: right;
      margin: 6rem 0rem 0 0rem; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-heading--title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 500;
      font-family: 'Open Sans', sans-serif;
      line-height: 1;
      color: #cdb45c; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-heading--title {
      display: block;
      margin: 24px 0 6px;
      font-family: 'Open Sans', sans-serif;
      color: #1c252c;
      letter-spacing: -0.015em; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-text--strong {
      font-size: 14px;
      color: #cdb45c; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text--strong {
      font-weight: bold; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .address-le-view-invoice {
      margin-top: 0px;
      display: block; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-text {
      font-size: 11px;
      color: #cdb45c; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text, .invoice-le-view_area .invoice-previe-div-body-le .le-text--body {
      margin: 0px 0; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__divider--full-width .le-divider {
      margin-left: -16px;
      margin-right: -16px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-divider {
      display: block;
      height: 0;
      padding: 0;
      border: none;
      border-bottom: 1px solid #d4dde3;
      overflow: hidden;
      margin: 24px auto; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__divider--full-width .le-divider {
      margin-left: -16px;
      margin-right: -16px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-divider-top {
      display: block;
      height: 0;
      padding: 0;
      border: none;
      border-bottom: 1px solid #ffffff;
      overflow: hidden;
      margin: 24px auto; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-divider-below {
      display: block;
      height: 0;
      padding: 0;
      border: none;
      border-bottom: 2px solid #d4dde3;
      overflow: hidden;
      margin: 24px auto; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__header__logo-invoice-view {
      margin-left: 14px;
      margin-right: -16px;
      display: flex; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata__customer {
      flex-grow: 1;
      display: flex;
      width: 67%; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata__customer .le-text--emphasized {
        font-family: 'Open Sans', sans-serif; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details {
      margin-top: 5px; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .le-table--plain {
        width: 100%;
        border-collapse: collapse;
        font-size: 16px;
        line-height: 1.4; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .invoice-contemplary-body-inside .le-table__row {
        border-bottom: inherit; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .contemporary-template__metadata .invoice-template-details .le-table__cell:first-child {
        text-align: right; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .contemporary-template__metadata .invoice-template-details .le-table__cell {
        padding-top: 0;
        padding-bottom: 0; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .invoice-contemplary-body-inside .le-table__cell:first-child {
        padding-left: 10px; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .invoice-contemplary-body-inside .le-table__cell {
        vertical-align: top;
        white-space: pre-line;
        word-break: break-word; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__metadata .invoice-template-details .invoice-contemplary-body-inside .le-table__cell {
        font-size: 14px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text--strong-invoice {
      font-family: 'Open Sans', sans-serif !important;
      color: #565656; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text--issuedate-invoice {
      font-family: 'Open Sans', sans-serif !important;
      color: #565656; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text--invoice {
      font-family: 'Open Sans', sans-serif !important;
      color: #565656; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__items-table {
      margin-top: 26px;
      margin-right: -16px;
      margin-left: -16px; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__items-table .invoice-contemplary-body-inside .le-table {
        font-size: 14px; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__items-table .le-table {
        width: 100%;
        border-collapse: collapse; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__items-table thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-table__header .le-table__row:last-child {
      border-color: #b2c2cd; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__row {
      border-bottom: inherit; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-table__header .le-table__row {
      border-width: 2px; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__header .le-table__cell {
      text-transform: inherit;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #fff; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__cell {
      vertical-align: top;
      white-space: pre-line;
      word-break: break-word; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__header .le-table__cell--amount {
      text-transform: inherit;
      font-weight: 600;
      padding-top: 20px;
      padding-bottom: 20px;
      color: #fff; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__cell--amount {
      padding-left: 15px;
      padding-right: 15px;
      width: 125px;
      vertical-align: center;
      white-space: pre-line; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__cell--amount {
      font-size: 14px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-table__body .le-table__row:last-child {
      border-color: #d4dde3; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__row {
      border-bottom: inherit; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__cell:first-child {
      padding-left: 5px !important; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__items-table .no-items-message {
      text-align: left;
      margin-left: 10px !important;
      padding: 10px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text--emphasized {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__divider--bold .le-divider {
      border-width: 3px;
      margin-top: 0;
      margin-bottom: 16px; }
    @media (max-width: 1100px) and (min-width: 580px) {
      .invoice-le-view_area .invoice-previe-div-body-le .how-text-top12-invoice-area {
        padding: 20px 0 0 0; } }
    @media (max-width: 7000px) and (min-width: 800px) {
      .invoice-le-view_area .invoice-previe-div-body-le .how-it-work-banner-top-heading-invoice {
        font-size: 20px;
        font-family: 'Open Sans', sans-serif; } }
    @media (max-width: 7000px) and (min-width: 800px) {
      .invoice-le-view_area .invoice-previe-div-body-le .how-it-work-banner-top-co-invoice {
        width: 30ch; } }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 30px; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text--body-totals__amounts {
        margin: 16px 0; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text--strong-totals__amounts {
        font-size: 14px !important;
        color: #000000; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text-totals__amounts {
        font-size: 16px !important; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text-totals__amounts-file {
        font-size: 13px !important; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text--strong-totals__amounts {
        font-family: 'Open Sans', sans-serif; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text-totals__amounts,
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .le-text-totals__amounts-file {
        padding-bottom: 0px;
        margin-left: -16px;
        margin-right: -16px;
        margin: 24px auto; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .contemporary-template__totals__amounts__line {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .contemporary-template__totals__amounts__line .contemporary-template__totals__amounts__line__amount {
          margin-left: 30px;
          min-width: 135px; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts .contemporary-template__divider--small-margin .le-divider {
        margin: 12px auto; }
    .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__divider--full-width-sign {
      margin: 12px auto; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__divider--full-width-sign .le-divider-amount-small {
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        margin: 24px auto; }
      .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__divider--full-width-sign .le-divider-amount-small {
        border-width: 3px;
        margin-top: 10px;
        margin-bottom: 10px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-text-al-payment-amount-usingcash {
      display: flex;
      justify-content: space-between; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-text-al-payment-amount-usingcash .le-text-al-payment-amount-usingcash {
        font-size: 14px;
        color: #8d8c8c;
        margin-top: 0px; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-text-al-payment-amount-usingcash .text-authorized-payment-amount-usingcash {
        font-size: 14px;
        color: #8d8c8c;
        font-family: 'Open Sans', sans-serif; }
    .invoice-le-view_area .invoice-previe-div-body-le .media-ride-list-invoice {
      /*   margin-top: 75px; */ }
    .invoice-le-view_area .invoice-previe-div-body-le .list-steps-of-ride-invoice, .invoice-le-view_area .invoice-previe-div-body-le .list-steps-of-ride-invoice:hover {
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-table-customer-data-heading {
      font-size: 20px;
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 20px; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table-customer-data-heading .box-gradient-icon-invoice-view {
        color: #cdb45c;
        background-color: #cdb45c;
        font-size: 18px;
        margin-right: 5px;
        height: 0px; }
    .invoice-le-view_area .invoice-previe-div-body-le .description_of_card_of_blogpost2 {
      display: flex;
      justify-content: center !important;
      margin-top: 30px;
      margin-bottom: 2rem;
      font-size: 600;
      color: #8d8c8c; }
      @media (max-width: 7000px) and (min-width: 500px) {
        .invoice-le-view_area .invoice-previe-div-body-le .description_of_card_of_blogpost2 .description_of_card_of_newsroom_area-blogpost2 {
          width: 95%; } }
      .invoice-le-view_area .invoice-previe-div-body-le .description_of_card_of_blogpost2 .invoice-description-area-of-preview--text {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        color: #565656;
        margin-top: 0;
        margin-bottom: 1rem; }
      .invoice-le-view_area .invoice-previe-div-body-le .description_of_card_of_blogpost2 .invoice-description-area-of-preview---belowfooter-text {
        font-size: 13px;
        font-family: 'Open Sans', sans-serif;
        color: #565656;
        margin-bottom: 1rem;
        text-align: right;
        position: absolute;
        bottom: 0 !important; }
      .invoice-le-view_area .invoice-previe-div-body-le .description_of_card_of_blogpost2 .blogs-description-area-of-main-headings-blogpost2 {
        font-size: 20px;
        font-family: 'Open Sans', sans-serif;
        color: #565656;
        text-align: left;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2; }
      .invoice-le-view_area .invoice-previe-div-body-le .description_of_card_of_blogpost2 .wow-news-card1-work-page-top-image-blogpost2 {
        margin: 2rem 0 2rem 0; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-table-customer-data-heading-behind-table {
      font-size: 20px;
      font-family: 'Open Sans', sans-serif;
      margin-bottom: 15px;
      color: #2b2a2a; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table-customer-data-heading-behind-table .box-gradient-icon-invoice-view {
        color: #cdb45c;
        padding-right: 15px;
        font-size: 15px; }
    .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table {
      width: 100%;
      border-collapse: collapse;
      font-size: 16px;
      line-height: 1.4; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .le-table__row-behind-table:last-child {
        border-color: #d4dde3; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .le-table__body .le-table__row-behind-table:last-child {
        border-color: #d4dde3; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .le-table__header .le-table__row-behind-table:last-child {
        border-color: #b2c2cd; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .le-table__header .le-table__row-behind-table {
        border-width: 2px; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .invoice-contemplary-body-inside .le-table__row-behind-table {
        border-bottom: inherit; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .contemporary-template__metadata .invoice-template-details .le-table__cell-behind-table:first-child {
        text-align: right; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .contemporary-template__metadata .invoice-template-details .le-table__cell-behind-table {
        padding-top: 0;
        padding-bottom: 0; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .invoice-contemplary-body-inside .le-table__cell-behind-table:first-child {
        padding-left: 10px; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .invoice-contemplary-body-inside .le-table__cell-behind-table:first-child {
        padding-left: 5px !important; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table [class*="cell-"] {
        padding-left: 0px;
        padding-right: 15px; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .invoice-contemplary-body-inside .le-table__cell-behind-table {
        vertical-align: top;
        white-space: pre-line;
        word-break: break-word; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .invoice-contemplary-body-inside .le-table__cell-behind-table {
        font-size: 14px; }
      .invoice-le-view_area .invoice-previe-div-body-le .le-table--plain-behind-table .le-text--strong-invoice-behind-table {
        font-family: 'Open Sans', sans-serif;
        color: #565656; }
    .invoice-le-view_area .invoice-previe-div-body-le .date-info-invoice-le {
      margin-left: 20px; }
    .invoice-le-view_area .invoice-previe-div-body-le .amount-padding-color-price {
      background-color: #cdb45c;
      display: flex;
      justify-content: flex-end;
      font-size: 18px;
      padding: 15px;
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 12px; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header__logo-inside-tale-customer {
      text-align: left; }
    .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header__logo-inside-tale-customer img {
      max-height: 100px;
      max-width: 100px; }

@media (max-width: 1125px) and (min-width: 700px) {
  .invoice-le-view_area .invoice-previe-div-body-le {
    display: flex;
    justify-content: space-between;
    margin: 24px auto;
    width: 665px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); }
  .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside {
    font-size: 14px;
    position: relative;
    padding: 10px;
    max-width: 650px;
    min-height: 1024px;
    box-sizing: border-box;
    border: 1px solid transparent;
    background: #fff;
    display: flex;
    flex-direction: column; }
  .invoice-le-view_area .invoice-previe-div-body-le .media-ride-list-invoice {
    margin-top: 0px; }
  .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header__logo {
    text-align: right;
    margin-top: 30px;
    margin-right: 15px; }
  .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside__header__logo img {
    max-height: 110px;
    max-width: 200px; }
  .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__header__info {
    justify-content: right;
    margin: 2rem 0rem 0 0rem; }
  .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-text--strong {
    font-size: 12px;
    color: #cdb45c; }
  .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-heading--title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    color: #cdb45c; }
  .description_of_card_of_invoicetable {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 70px;
    font-size: 600;
    color: #8d8c8c; }
  .description_of_card_of_invoicetable_area {
    width: 100%; }
  .invoice-le-view_area .invoice-previe-div-body-le .invoice-contemplary-body-inside .le-table__cell--amount {
    font-size: 10.2px; }
  .invoice-le-view_area .invoice-previe-div-body-le .contemporary-template__totals__amounts {
    display: flex;
    margin-right: 30px; }
  .invoice-le-view_area {
    padding: 50px 0; } }

.preview-invoiece-top-area {
  width: 100%;
  height: 300px;
  background-color: #BD9B3E; }
  .preview-invoiece-top-area p {
    font-family: 'Open Sans', sans-serif; }

.preview-invoiece-top-area-top {
  width: 70px;
  height: 80px;
  background-color: #CBBC99; }

/* #BD9B3E
#CBBC99
*/
