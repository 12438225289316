.invoive-Filter-Form{
    .formInput{
        margin-bottom: 30px;
        input,
        textarea,
        select{
            background: rgba(192, 181, 150, .2);
            height: 30px;
            padding: 3px 8px;
            color: #666666;
            margin-left: 10px;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 14px;
            }
        }
        select{
            color: #bbb5a5;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: rgb(72, 70, 76) url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px;
            padding: 15px;
        }
        p{
            text-transform: uppercase;
            font-size: 12px;
            color: red;
        }
    }
    
    button{
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        padding: 9px 25px;
        border: 2px solid transparent;
        text-transform: capitalize;
        display: inline-block;
        transition: all .3s;
        background: #c0b596;
        &:hover{
            background:#d4c291;
        }
    }
}
.INvoive-main-list-action{
         
    @media (min-width:1100px) {
      text-align: right;
     /*  margin-left: 55px; */
    }
    li{
        display: inline-block;
        position: relative;
        padding: 0px 10px;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
        @media (max-width:1200px) {
            padding: 0px 15px;
        }
        a{
            display: block;
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            letter-spacing: 0;
            padding: 24px 0px;
            text-transform: uppercase;
            cursor: pointer;
            i{
                display: none;
            }
            @media (max-width:1200px) {
                font-size: 11px;
            }
            &.active{
                color: #c0b596;
                @media (min-width:1000px) {
                  border-bottom:5px solid #c0b596;
                }
               
            }&.hover{
             
          }
        }
        
        &:hover{
          a{
            display: block;
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            letter-spacing: 0;
            padding: 24px 0px;
            text-transform: uppercase;
            @media (min-width:1000px) {
              border-bottom:5px solid #c0b596;
            }
            cursor: pointer;
            i{
                display: none;
            }
            @media (max-width:1200px) {
                font-size: 11px;
            }
            &.active{
                color: #c0b596;
            }
        }
            span{
                color: #c0b596;
            }
        }
        ul.subMenu{
            position: absolute;
            right: 0;
            top: 30px;
            z-index: 10000000;
            width: 156px;
            padding: 5px 12px;
            background: #fff;
            text-align: left;
            box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2);
            -webkit-transform: scaleY(0);
            -ms-transform: scaleY(0);
            -o-transform: scaleY(0);
            transform: scaleY(0);
            transform-origin: top;
            -webkit-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            text-transform: capitalize;
            li{
                display: block;
                padding: 0;
                cursor: pointer;
          padding: 2px 0px 2px 0px;
                .add-border-drop{
                    border-bottom: 1px solid #f2f2f2 !important;
                }
                &:last-child{
                    border-bottom: none;

                }
                a{
                    font-size: 15px;
                    color: #06163a;
                    display: block;
                    border-bottom: 1px solid #f2f2f2 !important;
                    padding: 12px 15px;
                    text-transform: capitalize;
                    &:hover,
                    &.active{
                      border-bottom: 1px solid #f2f2f2 !important;
                        color: #c0b596;
                    }
                }
                &:hover{
background-color: #f2f2f2;
                }
            }
        }
        ul.subMenu1{
          position: fixed;
          top: 168px;
          margin: 0% auto; /* Will not center vertically and won't work in IE6/7. */
        left: 0;
         right: 0;
          z-index: 999;
          max-width: 1100px;
          padding: 5px 12px;
          background: #CDB45C;
          text-align: left;
          box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
          -webkit-transform: scaleY(0);
          -ms-transform: scaleY(0);
          -o-transform: scaleY(0);
          transform: scaleY(0);
          transform-origin: top;
          -webkit-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          text-transform: capitalize;
          li{
              display: block;
              padding: 0;
             /*  border-bottom: 1px solid #f2f2f2; */
              &:last-child{
                  border-bottom: none;

              }
              a{
                  font-size: 1rem;
                  color: white;
                  display: block;
          /*         border-bottom: 1px solid #f2f2f2; */
                  padding: 12px 1px;
                  text-transform: capitalize;
                  &:hover,
                  &.active{
             /*   border-bottom: 1px solid #f2f2f2;  */
                      color: #686764;
                  }
              }
          }
      }
      &:hover{
        ul.subMenu1{
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            -o-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
        &:hover{
            ul.subMenu{
                -webkit-transform: scaleY(1);
                -ms-transform: scaleY(1);
                -o-transform: scaleY(1);
                transform: scaleY(1);
            }
        }
    }
}
/* .table-area-invoive{
    overflow-y: hidden !important;
} */
.AddInvoice-Area{

    .addInvoiceTpArea{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px;
        .addInvoiceTpArea-heading-left{
            p{
                font-size: 24px;
                font-weight: 550;
                color: #666666;
                margin-top: 10px;
            }

        }
        .addInvoiceTpArea-heading-right{
           display: flex;

            .addInvoiceTpArea-heading-left-type{
                border: 1px solid rgb(203, 204, 206);
                padding: 5px 10px 5px 10px;
                border-radius: 20px;
                font-size: 13px;
                font-weight: 550;
                cursor: pointer;
                margin-left: 10px;
                &:hover{
                    border: 1px solid #666666;
                    box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2);
                }
            }
            .addInvoiceTpArea-heading-left-save-cont{
                background-color: #666666;
                color: white;
                padding: 5px 10px 5px 10px;
                border-radius: 20px;
                font-size: 13px;
                font-weight: 550;
                cursor: pointer;
                margin-left: 10px;
                &:hover{
                    background-color: #3a3a3a; 
                    box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2);
             
                }
            }
        }
      
    }
    .AddInvoiceBottomArea{
        padding: 0px 20px;
        .InvoiceAccordationHeader{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .myAccordationInvoice{
             /*    transform: rotate(180deg); */
                transition-property: all;
                transition-duration: 0.3s;
                transition-timing-function: ease;
                transition-delay: 5s;
                font-size: 10px !important;
            }
            .myAccordationInvoice1{
                transition: all .3s;
            }
            .invoice-accordation-right-area{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100% !important;
            }
        }
       .invoice-basic-info-detail{
           margin-top: 30px;
        -webkit-box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
        -moz-box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
        box-shadow: 0px 2px 10px 0px rgba(138,138,138,1);
        border-radius: 10px;
        .invoice-basic-info-detail-top{
            padding: 15px;
            .invoice-basic-info-detail-top-col{
               
                display: flex;
                justify-content: center;
                align-items: center;
                .invoice-basic-info-detail-top-Add-Cutomer{
                    /* lllll */
                    .customer-addes-area{
                   padding: 10px;
                    }
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 260px;
                    height: 180px;
                    border-radius: 10px;
                    border: 1px solid #c0b596 !important;
                    background: #F3F0EA;
                    p{
                      /*   font-size: 24px; */
                        font-weight: 550;
                        cursor: pointer;
                        margin-bottom: 0px;
                    }
                }
            }
        }
        .invoice-basic-info-bottom-col-area{
.edit-col-area-main{
    display: flex;
    justify-content: flex-end;
            .edit-col-area{
                color: #666666;
                background: #F3F0EA;
                padding: 5px 10px 5px 10px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                margin-right: 30px;
                width: 150px;
                font-weight: 550;
                cursor: pointer;
      &:hover{
          text-decoration: underline;
      }

            }

        }
        .add-invoice-table{
          
            th{
                background: #F3F0EA !important;
               /*  width: 100%; */
                padding: 10px 5px 10px 5px
            }
        }
        .dont-have-Item-add-invoice{
            border-top: 1px solid lightgrey;
            border-bottom: 1px solid lightgrey;
            padding: 8px 0px;
            cursor: pointer;
            p{
                margin-bottom: 0px;
                font-size: 16px;
                font-weight: 550;
            }
            &:hover{
                background-color: rgb(250, 249, 249) ;
            }
        }
        .add-invocie-sub-total-area{
            padding: 10px 20px; 
            display: flex;
            justify-content: flex-end;
            .add-invocie-sub-total-area-box{
                display: flex;
                justify-content: space-between;
                font-size: 16px;
max-width: 400px;
      .add-invocie-sub-total-area-box-left{
          margin-right: 50px;
          .add-invocie-sub-total-area-box-left-data{
              display: flex;
              flex-wrap: wrap;
           align-items: center !important;
           margin-top: -30px;
          }
      }
            }
        }
            //message wrap
  .termsandCondtions-addInvoice-area::-webkit-scrollbar-track {
    background-color: transparent;
     
   }
   .termsandCondtions-addInvoice-area::-webkit-scrollbar {
     width: 7px;
    
   }
   
   .termsandCondtions-addInvoice-area::-webkit-scrollbar-thumb {
    
     background-color: #CCCCCC;
    /*  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
   }
        .termsandCondtions-addInvoice-area{
            padding: 10px 20px 30px 20px;
            border-top: 1px solid lightgray;
            height: 200px;
            margin-bottom: 30px;
            overflow: auto;
            p{
                margin-bottom: 0px;
            }

        }
        }
      
       } 
       .AddInvoiceForm{
        margin-top: 30px;
    }
    }

}
.dropSown-area-addcutomer{
    height: 300px !important;
    overflow-x: hidden;
    overflow-y: auto;
}
.dropSown-area-addcutomer::-webkit-scrollbar-track {
    background-color: transparent;
     
   }
   .dropSown-area-addcutomer::-webkit-scrollbar {
     width: 7px;
    
   }
   
   .dropSown-area-addcutomer::-webkit-scrollbar-thumb {
    
     background-color: #CCCCCC;
    /*  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);  */
   }
/* box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2); */
@media (min-width:991px){
    .add-invoice-item-drop-dwon{
  width: 680px !important;
  left: -270px !important;
  .add-invoice-dropdown-item-basic{
    max-width: 80% !important;

} 
    }
    .add-invoice-dropdown-item-basic1{
        margin-top: 15px !important;
    } 
}
@media (max-width:991px){
    .add-invoice-dropdown-item-basic1{
        display: none !important;
        max-width: 80% !important;
        margin-top: 15px;
    } 
}
.add-invoice-dropdown-item{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    flex-wrap: wrap !important;
}
.add-invoice-dropdown-item-basic{
    max-width: 80% !important;
 
}
.ui.dropdown .menu>* {
    white-space: pre-wrap !important; 
} 
.inovice-item-descript-area{
    border: 1px solid #CCCCCC;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
}
.inovice-item-descript-area-title{
    font-size: 16px;
    font-weight: 550;
   
}
.add-invoice-table-items{
    border-bottom: 2px solid lightgray;
    padding-bottom: 10px;
    padding-top: 10px !important;
}
.add-invoice-table-items:hover{
    background-color: #ECEAE5;
    border-bottom: 2px solid lightgray;
}
.addInvoiceTpArea-heading-left-type-print{
    border: 1px solid rgb(203, 204, 206);
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    font-size: 13px;
    width: 150px;
    margin-bottom: 10px;
    font-weight: 550;
    cursor: pointer;
    margin-left: 10px;
    &:hover{
        border: 1px solid #666666;
        box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2);
    }
}
.printScreenddddddddddddddddddddd{
    width: 1000px !important;
    align-self: center;
    
}
.ui.dropdown .scrolling.menu>.item.item.item, .ui.scrolling.dropdown .menu .item.item.item {
    min-width: 132px;
}

@media (max-width:7000px) and (min-width:500px){
    .modals.dimmer .ui.scrolling.modal {
        /* margin: 1rem auto; */
        /* margin: 0px auto; */
        margin-left: 20%;
        margin-top: 13%;
    }
}
@media (max-width:500px) and (min-width:0px){
    .modals.dimmer .ui.scrolling.modal {
        /* margin: 1rem auto; */
        /* margin: 0px auto; */
        margin-left: 5%;
        margin-top: 25%;
    }  
}
.custom-text-area-addInvoice{max-width:100%;flex:1 0 auto;outline:0;-webkit-tap-highlight-color:rgba(255,255,255,0);text-align:left;line-height:1.21428571em;font-family:Lato,Helvetica Neue,Arial,Helvetica,sans-serif;padding:.67857143em 1em;background:#fff;border:1px solid rgba(34,36,38,.15);color:rgba(0,0,0,.87);width:90%;border-radius:.28571429rem;-webkit-transition:box-shadow .1s ease,border-color .1s ease;transition:box-shadow .1s ease,border-color .1s ease;box-shadow:none;margin:8px 0 5px}