.progress-bar {
    height: 8px;
    background: #4caf50;
    width: 0%;
  }
  .progress-container {
      position: fixed;
      top: 0;
    width: 100%;
    height: 8px;
    background: #ccc;
  }
  .customer-signin-area{
    max-width: 550px;
    background: rgba(21, 26, 48, .66) !important;
    height: 300px !important;
    margin-top: -40px;
    padding: 30px;
  }
  .customer-signin-box{
    opacity: 1 !important;
  }
  .ourServiceAreaStyleTwo1{
    background: url(../../../images/practice/1.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 100px 0px 70px;
    &::before{
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(21, 26, 48, .66);
    }
    .sectionTitle{
        h2{
            color: #fff;
        }
    }
    .serviceWrap{
        background: rgba(192, 181, 150, .30);
        margin-bottom: 30px;
        padding: 20px 15px;
        height: 95%;
        .serviceIcon{
            height: 100%;
            border: none;
            width: auto;
            position: relative;
            padding-right: 15px;
            margin:0px;
            margin-right: 15px;
            &::before{
                content: "";
                position: absolute;
                right: 0;
                top: 50%;
                width: 1px;
                height: 65px;
                background: #BC9B41;
                transform: translateY(-50%);
            }
            i{
                color: #BC9B41;
                &::before{
                    font-size: 60px;
                }
            }
        }
        .serviceContent {
            h3{
                font-size: 24px;
                color: #fff;
                margin-bottom: 10px;
                padding-bottom: 0;
                @media (max-width:1100px) {
                    font-size: 20px;
                }
                @media (max-width:450px) {
                    font-size: 24px;
                }
                &::before{
                    display: none;
                }
            }
            p{
                font-size: 16px;
                color: #ddd;
                margin-bottom: 0;
                @media (max-width:450px) {
                    font-size: 14px;
                }
            }
        }
    }
}