


.status-cust-invoice{
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    justify-content: space-between;

    .status-cust-headings-invoice{
        font-size: 14px;
        font-weight: 550;
        font-family: 'Open Sans', sans-serif;
        color: #666666;
    }

    .status-cust-draft-invoice{
        font-size: 16px;
        font-family: 'Open Sans', sans-serif;
    
        padding: 10px;
        font-weight: 550;
        background: repeating-linear-gradient(
            -55deg,
            #222,
            #222 3px,
            #333 3px,
            #333 6px
          );
          color: white;
        padding: 5px 20px;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;

    }

    .status-cust-name-invoice{
        font-size: 17px;
        font-family: 'Open Sans', sans-serif;
        color: #000000 !important;
        font-weight: 600;
        

    }

    .exclamation-icon{
        padding-left: .5rem;

    }

    .status-cust-amount-invoice{
        font-size: 20px;
        font-weight: 550;
        font-family: 'Open Sans', sans-serif;
        color: #000000;
        
    }

    .status-cust-dueday-invoice{
        font-size: 20px;
        font-family: 'Open Sans', sans-serif;
        color: #000000;
        font-weight: 550;
        
    }
}


































.invoice-payment-voucher-view-area{
/* padding: 50px; */
.new-invoice-payment-voucher-header-text-area{
display: flex;
justify-content: space-between;
position: relative;
width: 100%;
border-radius: 8px;
margin-bottom: 24px;
padding: 20px;

.new-invoice-payment-voucher-header-text-area::before {
background: #007197;
}

@media (min-width: 480px){
h2 {
font-size: 33px;
font-weight: 550;
}
}
h2{
    margin: 0;
    font-size: 26px;
    line-height: 1.4;
    font-family: 'Open Sans', sans-serif;
}

.two-button-edi-save-new-invoice-payment-voucher{
display: -webkit-inline-flex;
display: inline-flex;
-webkit-align-items: center;
align-items: center;
margin: -4px;
-webkit-flex-wrap: wrap;
flex-wrap: wrap;
-webkit-align-self: flex-start;
align-self: flex-start;
margin-top: 0;

.dropdown-toggle-type-new-invoice-payment-voucher{
border-radius: 25px;
margin-right: 5px !important;


.dropdown-toggle::after {
display: inline-block;
margin-left: 1.255em !important;
vertical-align: .255em;
content: "";
color: #000000 !important;
border-top: .3em solid;
border-right: .3em solid transparent;
border-bottom: 0;
border-left: .3em solid transparent;
}



}


Button{
border-radius: 20px;
padding: 8px 20px;
font-size: 16px;
font-weight: 550;
text-align: center;
min-width: 100px;
border-radius: 500px;
display: inline-block;
box-sizing: border-box;
vertical-align: middle;
outline: 0;
font-family: Averta,sans-serif;
font-weight: 600;
cursor: pointer;
text-decoration: none;
-webkit-appearance: none;
}

.le-newinvoice-payment-voucher-edit-button{
margin: 4px;
margin-right: 5px !important;
padding: 8px 15px !important;

}

.le-button--with-actions>.le-button, .le-button--with-actions--savecontinue-newinvoice-paymentvoucher>.le-button {
border-top-right-radius: 0;
border-bottom-right-radius: 0;
border-right: 0;
padding-right: 16px;
margin-right: 0;
}

.le-button--submit, .le-button--primary, .le-button--with-actions--savecontinue-newinvoice-paymentvoucher .le-button {
color: #fff;
background: #136acd;
border: 1px solid transparent;
}

.le-button--with-actions--savecontinue-newinvoice-paymentvoucher .le-dropdown-newinvoice-paymentvoucher {
display: inline;
}

.le-dropdown-newinvoice-paymentvoucher {
position: relative;
display: inline;
}

[class*="le-button--with-actions"] .le-dropdown__toggle-newinvoice-paymentvoucher {
height: 100%;
}

.le-button--with-actions--savecontinue-newinvoice-paymentvoucher .le-dropdown__toggle-newinvoice-paymentvoucher {
border-left-color: rgba(255,255,255,0.5);
}

.le-button--with-actions .le-dropdown__toggle-newinvoice-paymentvoucher, .le-button--with-actions--savecontinue-newinvoice-paymentvoucher .le-dropdown__toggle-newinvoice-paymentvoucher {
border-top-left-radius: 0;
border-bottom-left-radius: 0;
padding: 9px 0 7px;
width: 36px;
min-width: 0;
border-left: 1px solid;
}

.le-button--with-actions--savecontinue-newinvoice-paymentvoucher .le-button {
color: #fff;
background: #2e2d2d;
border: 1px solid transparent;
}

.le-button--with-actions--savecontinue-newinvoice-paymentvoucher .le-dropdown__toggle-newinvoice-paymentvoucher.le-button .le-svg-icon {
margin-right: 2px;
}

.le-dropdown__toggle-newinvoice-paymentvoucher.le-button .le-svg-icon {
position: relative;
bottom: -4px;
margin-right: -5px;
}

.le-button .le-svg-icon{
margin-top: -1px;
margin-bottom: -1px;
position: relative;
bottom: -3px;
}

.le-button{
line-height: 1.4;
font-size: 16px;
font-weight: 550;
}

.is-open>.le-dropdown__menu--right-newinvoice-paymentvoucher {
visibility: visible;
height: auto;
-webkit-animation: panel-slide-down .1s ease-in-out forwards;
animation: panel-slide-down .1s ease-in-out forwards;
}

.le-dropdown__menu--right-newinvoice-paymentvoucher>li {
display: block;
}

.le-dropdown__menu__item-newinvoice-paymentvoucher {
position: relative;
vertical-align: middle;
}

.le-dropdown__menu--right-newinvoice-paymentvoucher {
list-style: none;
box-sizing: border-box;
margin: 0;
padding: 0;
text-align: left;
display: block;
visibility: hidden;
position: absolute;
top: 110%;
z-index: 1000;
min-width: 100%;
padding: 8px 0;
border-radius: 4px;
color: #1c252c;
background-color: white;
box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2);
-webkit-transform-origin: 0 0;
transform-origin: 0 0;
height: 0;
-webkit-transform: translateY(4px);
transform: translateY(4px);
overflow: hidden;
}
}







@keyframes panel-side-down {
0%, 30% {
    margin-top: -8px;
    opacity: 0;
}

100% {
    margin-top: 0;
    opacity: 1;
}

}


}



.invoice-preview__warning-message {
text-align: center;
margin-top: 40px;
margin-bottom: -10px;
}

.le-text, .le-text--body {
margin: 16px 0;
}

.invoice-previe-div-body-le{
display: flex;
justify-content: space-between;
margin: 24px auto;
width: 950px;
box-shadow: 0 2px 8px rgba(0,0,0,.2);

.invoice-contemplary-body-inside {
font-size: 14px;
font-weight: 550;
position: relative;
padding: 30px 50px;
/*   min-height: 1024px; */
box-sizing: border-box;
border: 1px solid transparent;
background: #fff;
display: flex;
flex-direction: column;
}

.invoice-contemplary-body-inside{
font-family: 'Open Sans', sans-serif;
}

.invoice-contemplary-body-inside__header__logo {
text-align: right;
margin-top: 70px;
margin-right: 45px;

}

.logo-txt-setion{
display: flex;
justify-content: space-between;

}

.invoice-contemplary-body-inside__header__logo img {
max-height: 150px;
max-width: 200px;

}



.invoice-contemplary-body-inside .le-heading--title {
margin-top: 0;
margin-bottom: 0;
font-size: 20px;
font-weight: 550;
font-family: 'Open Sans', sans-serif;
line-height: 1;
color: #cdb45c;
}

.le-heading--title{
display: block;
margin: 24px 0 6px;
font-family: Averta,sans-serif;
color: #1c252c;
letter-spacing: -0.015em;
}

.invoice-contemplary-body-inside .le-text--strong {
font-size: 14px;
color: #cdb45c;
font-weight: 550;
}

.le-text--strong {
font-weight: bold;
}

.invoice-contemplary-body-inside .address-le-view-invoice {
margin-top: 0px;
display: block;

}

// .invoice-contemplary-body-inside .address__field-invoice-view .le-text {
//     line-height: 1px;



// }

.invoice-contemplary-body-inside .le-text {
font-size: 11px;
color: #cdb45c;
font-weight: 550;

}


.contemporary-template__divider--full-width .le-divider {
margin-left: -16px;
margin-right: -16px;
}

.le-divider {
display: block;
height: 0;
padding: 0;
border: none;
border-bottom: 1px solid #d4dde3;
overflow: hidden;
margin: 24px auto;
}



.contemporary-template__divider--full-width .le-divider {
margin-left: -16px;
margin-right: -16px;
}


}





.le-text--strong-invoice {
font-family: 'Open Sans', sans-serif !important;
color: #666666 ;
}







.contemporary-template__divider--bold .le-divider {
    border-width: 3px;
    margin-top: 0;
    margin-bottom: 16px;
}

    
}
















// media queries


@media(max-width:1120px) and (min-width:700px){

.invoice-payment-voucher-view-area .invoice-previe-div-body-le {
    display: flex;
    justify-content: space-between;
    margin: 24px auto;
    width: 650px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.status-cust-invoice {
display: flex;
flex-direction: column !important;
width: 50% !important;
justify-content: space-between;
}
.invoice-previe-div-body-le-small-box {
display: flex;
justify-content: space-between;
margin: 24px 10px;
width: 630px !important;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.invoice-previe-div-body-le-small-box-invoicegetpaid {
display: flex;
justify-content: space-between;
margin: 24px 10px;
width: 630px !important;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

}
































.contemporary-template__divider--full-width .le-divider-top-newinvoiceone {
margin-left: 76px;
margin-right: 16px;
}
.le-divider-top-newinvoiceone {
display: block;
height: 0;
padding: 0;
border: none;
border-bottom: 2px solid #d4dde3;
overflow: hidden;
margin: 10px auto;
}


.invoice-one-border-box{
display: flex;
justify-content: space-between;
margin-top: 20px;

}

.invoice-one-border-box-invoicegetpaid{
display: flex;
justify-content: space-between;
margin-top: 10px;
margin-bottom: 30px;

}


.le-newinvoice-payment-voucher-edit-button{
border-radius: 25px !important;
margin-top: 15px ;
}

.le-record-payment-voucher-edit-button{
    border-radius: 25px !important;
    margin-top: 1px !important;
}












.invoice-previe-div-body-le-small-box{
display: flex;
justify-content: space-between;
margin: 24px auto;
width: 830px;
box-shadow: 0 2px 8px rgba(0,0,0,.2);

.invoice-contemplary-body-inside-small-box {
    font-size: 14px;
    position: relative;
    font-weight: 550;
    padding: 10px;
    min-height: 124px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.media-ride-list-invoice-small-box {
margin-top: 5px;

.list-steps-of-ride-invoice-small-box, .list-steps-of-ride-invoice-small-box:hover {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .invoice-contemplary-body-inside__header__logo-small-box {
        text-align: right;
        margin-top: 0px;
        margin-right: 25px;

    }

    .invoice-contemplary-body-inside__header__logo-small-box img {
        max-height: 55px;
        max-width: 55px;
    }

        
}

}
}






.contemporary-template__header__info-small-box {
justify-content: right;
margin: 0rem 0rem 0 0rem;

.le-heading--title-small-box {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 550;
    font-family: 'Open Sans', sans-serif;
    line-height: 1;
    color: #cdb45c;
}

.le-heading--title-small-box{
    display: block;
    margin: 14px 0 6px;
    color: #666666;
    letter-spacing: -0.015em;
}

.le-text--strong-small-box {
    font-size: 14px;
    font-weight: 550;
    color: #000000;
    font-weight: bold;
}

}











.invoice-previe-div-body-le-small-box-invoicegetpaid{
display: flex;
justify-content: space-between;
margin: 15px auto;
width: 830px;
box-shadow: 0 2px 8px rgba(0,0,0,.2);
/* border: 2px solid #66666688; */

.invoice-contemplary-body-inside-small-box-invoicegetpaid {
    font-size: 14px;
    position: relative;
    padding: 0px 10px;
    min-height: 104px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid transparent;
    background: #fff;
    display: flex;
    flex-direction: column;
}

.media-ride-list-invoice-small-box-invoicegetpaid {
margin-top: 5px;

.list-steps-of-ride-invoice-small-box-invoicegetpaid, .list-steps-of-ride-invoice-small-box-invoicegetpaid:hover {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid {
        text-align: right;
        margin-top: 0px;
        margin-right: 25px;

    }

    .invoice-contemplary-body-inside__header__logo-small-box-invoicegetpaid img {
        max-height: 55px;
        max-width: 55px;
         
    }

        
}
}
}








.contemporary-template__header__info-small-box-invoicegetpaid {
        justify-content: right;
        margin: 0rem 0rem 0 0rem;

        .le-heading--title-small-box-invoicegetpaid {
            margin-top: 0;
            margin-bottom: 20px !important;
            font-size: 21px;
            font-weight: 550;
            font-family: 'Open Sans', sans-serif;
            line-height: 1;
            color: #cdb45c;
        }
    
        .le-heading--title-small-box-invoicegetpaid{
            display: block;
            margin: 18px 0 6px;
            color: #666666;
            letter-spacing: -0.015em;
        }

        .le-text--strong-small-box-invoicegetpaid {
            font-size: 14px;
            color: #666666;
            font-family: 'Open Sans', sans-serif;
        }

        .le-text--strong-small-box-getpaid-invoicegetpaid {
            font-size: 14px;
            color: #000000;
            font-family: 'Open Sans', sans-serif;
        }

}















.send-invoice-button-of-newinvoice{
    display: flex;
    justify-content: flex-end;
}

.le-newinvoice-payment-send-invoice-button{
    padding: 8px 35px;
    
}

.le-newinvoice-payment-send-invoice-button{
    border-radius: 25px !important;
    margin: 25px 10px !important;
}




.class-modal-1-invoice{
    display: block;

    
}




.ui.modal>.close {
    cursor: pointer;
    position: absolute;
    top: 0.9rem;
    right: .8rem;
    z-index: 1;
    color: #000000 !important;
    opacity: .8;
    font-size: 1.25em;
    color: #FFF;
    width: 2.25rem;
    height: 2.25rem;
    padding: .625rem 0 0;
}



.description_of_card_of_newnoinvoice {
    display: flex;
    justify-content: center;
    font-size: 600;

    
    .description_of_card_of_newnoinvoice_area {
        width: 60%;
        padding: 50px 0;
    }

    .label-form-newnoinvoice{
        padding: 10px 1px;
        

        label {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            color: #666666;
            font-weight: 700;
        }
    }

    .label-form-newnoinvoice-invoice11{
        padding: 10px 3px;
        

        label {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            color: #666666;
            font-weight: 700;
        }

    }

    
}



.description_of_card_of_recordpayment {
    display: flex;
    justify-content: center;
    font-size: 600;

    
    .description_of_card_of_recordpayment_area {
        width: 60%;
        padding: 10px 0;
    }

    .label-form-newnoinvoice{
        padding: 10px 1px;
        

        label {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            color: #666666;
            font-weight: 700;
        }
    }

    .label-form-newnoinvoice-invoice11{
        padding: 10px 3px;
        

        label {
            font-size: 18px;
            font-family: 'Open Sans', sans-serif;
            color: #666666;
            font-weight: 700;
        }

    }

    
}

.sent-invoice-tick-image-modal{
    padding: 50px;
    text-align: center;

    p{
        font-size: 26px;
        font-family: 'Open Sans', sans-serif;
        color: #252525;
        font-weight: 700;
        margin-top: 10px;
    }
}



.email-text-inside-modal-1{
    border-bottom: 1px solid #d4dde3;
    margin-left: -16px;
    margin-right: -16px;

    p{
    font-size: 24px;
    padding-top: -3rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 550;
    margin-left: 16px;
    border-bottom: 5px solid #000000;
    width: 8%;

    }
}


.recordpayment-text-inside-modal-1{
    padding: 0;

    p{
    font-size: 18px;
    padding-top: -3rem;
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;

    }
}
























.record-a-payment-form-area{
    padding: 10px;
    max-width: 440px;
}
.bottom-button-area-recored-paymenfomr{
display: flex !important;
justify-content: space-between !important;
flex-wrap: wrap !important;
align-items: center;
margin-top:30px;
}
.bottom-button-area-recored-paymenfomr-submit{
padding: 5px 15px 5px 15px;
background-color: #666666;
color: white;
border-radius: 15px;
margin-left: 15px;
}
.bottom-button-area-recored-paymenfomr-submit:active{
    padding: 10px 25px 10px 25px;
    background-color: #000000;
    color: white;
    border-radius: 15px;
    outline: none;
    border: none;
/*     margin-left: 15px; */
    }
    .bottom-button-area-recored-paymenfomr-submit:focus{
        padding: 3px 15px 5px 15px;
        background-color: #000000;
        color: white;
        border-radius: 15px;
        outline: none;
        border: none;
    /*     margin-left: 15px; */
        }
.bottom-button-area-recored-paymenfomr-cancel{
    padding: 5px 15px 5px 15px;
    border: 1px solid #666666;
    color: #666666;
    background-color: white;
    border-radius: 15px;
}
.bottom-button-area-recored-paymenfomr-cancel:active{
    padding: 5px 15px 5px 15px;
    border: 1px solid #666666;
    color: #666666;
    background-color: white;
    border-radius: 15px;
    outline: none;
    border: none;
}
.invoice-expense-docs-area{
    padding: 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    h3{
        font-size: 20px;
        color: #666666;
        font-weight: 700;
        font-family: 'Open Sans', sans-serif;
    } 
    .invoice-IMages-area{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .expense-doc-item{
            margin: 5px;
            width: 160px;
         
        }
        h6{
            font-size: 16px;
            color: #666666;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
        } 
        img{
            width: 100%;
            cursor: zoom-in !important;
        }
    }
}

