
.invoiceStatus{
    color: #A90941;
/*     background-color: #EBB7B2; */
    padding: 3px 5px 3px 5px;
    font-size: 12px;
    font-weight: 550;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;


}
.invoiceStatusSent{
    color: green;
 /*    background-color: rgb(119, 190, 119); */
    padding: 3px 5px 3px 5px;
    font-size: 12px;
    font-weight: 550;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;  
}
.my-invoice-filter-col{
    padding: 0px 6px !important;
}
.my-invoice-filter-col1{
    padding: 0px 20px 0px 6px !important;
}
.invoiceStatusAction{
    padding: 3px 3px 3px 3px;
    border: 1px solid #666666;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}
.invoice-data-list-cutomer:focus{
    outline: turquoise !important;

}
.invoice-filter-area{
    margin-bottom: -30px;
    margin-top: 30px;
}
.filter-counter-area-invoice{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.filter-counter-area-invoice-counter{
    width: 25px;
     height: 25px;
     border-radius: 50%;
     font-size: 12px;
     font-weight: 550;
     margin-right: 5px;
     background-color: #EBEFF4;
     display: inline;
     display: flex;
     justify-content: center;
     align-items: center;
}
.filter-counter-area-invoice-left{
    display: flex;
     justify-content: flex-start;
     font-size: 14px;
     font-weight: 550;
}
.filter-counter-area-invoice-right{
    font-size: 15px;
    font-weight: 550;
    text-decoration: underline;
    color: #666666;

}
.invoice-filter-close{
position: relative;
}
.invoice-filter-close1{
    position: absolute;
    top:3px;
    right: 22px;
    font-size: 15px;
    cursor: pointer;
}
.invoice-filter-close2{
    position: absolute;
    top:3px;
    right: 15px;
    font-size: 15px;
    cursor: pointer;
}
.icono5454{
    font-size: 10px !important;
    animation: 0.5s ease-in-out;
}
.nd-invoices-filter-area-items{
font-size: 15px; 
font-weight: 550;
border-bottom: 1px solid #EBEFF4;
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
padding: 0px 10px;
margin-top: 10px;
margin-bottom: -20px;
padding-bottom: 14px;
}
.nd-invoices-filter-area-items-all{
    display: flex;
    justify-content: flex-start;
    padding-bottom: 3px;
    margin-bottom: -11px;
    cursor: pointer;
    margin-right: 20px;
    margin-top: 20px;


}
.nd-invoices-filter-area-items-all:hover{
    display: flex;
    justify-content: flex-start;
    border-bottom: 4px solid rgb(212, 216, 221);
    padding-bottom: 3px;
    margin-bottom: -15px;
    cursor: pointer;
    margin-right: 20px;

}
/* .container-custom{
    max-width: 1300px;
    align-items: center;
    align-self: center !important;
    margin: 0 auto;
} */
.custom-text-area-addInvoice{
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    padding: .67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    width: 90%;
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,border-color .1s ease;
    box-shadow: none;
    margin: 8px 0px 5px 0px;
}