.invoive-Filter-Form{
    .formInput{
        margin-bottom: 30px;
        input,
        textarea,
        select{
            background: rgba(192, 181, 150, .2);
            height: 30px;
            padding: 3px 8px;
            color: #666666;
            margin-left: 10px;
            border-radius: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 14px;
            }
        }
        select{
            color: #bbb5a5;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: rgb(72, 70, 76) url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px;
            padding: 15px;
        }
        p{
            text-transform: uppercase;
            font-size: 12px;
            color: red;
        }
    }
    
    button{
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        padding: 9px 25px;
        border: 2px solid transparent;
        text-transform: capitalize;
        display: inline-block;
        transition: all .3s;
        background: #c0b596;
        &:hover{
            background:#d4c291;
        }
    }
}
.INvoive-main-list-action{
         
    @media (min-width:1100px) {
      text-align: right;
     /*  margin-left: 55px; */
    }
    li{
        display: inline-block;
        position: relative;
        padding: 0px 10px;
        &:first-child{
            padding-left: 0;
        }
        &:last-child{
            padding-right: 0;
        }
        @media (max-width:1200px) {
            padding: 0px 15px;
        }
        a{
            display: block;
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            letter-spacing: 0;
            padding: 24px 0px;
            text-transform: uppercase;
            cursor: pointer;
            i{
                display: none;
            }
            @media (max-width:1200px) {
                font-size: 11px;
            }
            &.active{
                color: #c0b596;
                @media (min-width:1000px) {
                  border-bottom:5px solid #c0b596;
                }
               
            }&.hover{
             
          }
        }
        
        &:hover{
          a{
            display: block;
            font-size: 15px;
            font-weight: 400;
            color: #fff;
            letter-spacing: 0;
            padding: 24px 0px;
            text-transform: uppercase;
            @media (min-width:1000px) {
              border-bottom:5px solid #c0b596;
            }
            cursor: pointer;
            i{
                display: none;
            }
            @media (max-width:1200px) {
                font-size: 11px;
            }
            &.active{
                color: #c0b596;
            }
        }
            span{
                color: #c0b596;
            }
        }
        ul.subMenu{
            position: absolute;
            right: 0;
            top: 26px;
            z-index: 10000000;
            width: 156px;
            padding: 5px 12px;
            background: #fff;
            text-align: left;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
            -webkit-transform: scaleY(0);
            -ms-transform: scaleY(0);
            -o-transform: scaleY(0);
            transform: scaleY(0);
            transform-origin: top;
            -webkit-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            text-transform: capitalize;
            li{
                display: block;
                cursor: pointer;
                color: #666666 !important;
          padding: 4px;
                .add-border-drop{
                    border-bottom: 1px solid #f2f2f2 !important;
                }
                &:last-child{
                    border-bottom: none;

                }
                a{
                    font-size: 15px;
                    color: #06163a;
                    display: block;
                    border-bottom: 1px solid #f2f2f2 !important;
                    padding: 12px 15px;
                    text-transform: capitalize;
                    &:hover,
                    &.active{
                      border-bottom: 1px solid #f2f2f2 !important;
                        color: #c0b596;
                    }
                }
                &:hover{
                    color: #06163a !important;
                }
            }
        }
    
        &:hover{
            ul.subMenu{
                -webkit-transform: scaleY(1);
                -ms-transform: scaleY(1);
                -o-transform: scaleY(1);
                transform: scaleY(1);
            }
        }
        ul.subMenu1{
            position: absolute;
            left: 0;
            top: 25px;
            z-index: 10000000;
            width: 156px;
            padding: 5px 12px;
            background: #fff;
            text-align: left;
            box-shadow: 0 0 0 1px rgba(77,101,117,0.1), 0 3px 10px 0 rgba(77,101,117,0.2);
            -webkit-transform: scaleY(0);
            -ms-transform: scaleY(0);
            -o-transform: scaleY(0);
            transform: scaleY(0);
            transform-origin: top;
            -webkit-transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            transition: all 0.3s ease 0s;
            text-transform: capitalize;
            li{
                display: block;
                padding: 0;
                cursor: pointer;
          padding: 2px 0px 2px 0px;
                .add-border-drop{
                    border-bottom: 1px solid #f2f2f2 !important;
                }
                &:last-child{
                    border-bottom: none;

                }
                a{
                    font-size: 15px;
                    color: #06163a;
                    display: block;
                    border-bottom: 1px solid #f2f2f2 !important;
                    padding: 12px 15px;
                    text-transform: capitalize;
                    &:hover,
                    &.active{
                      border-bottom: 1px solid #f2f2f2 !important;
                        color: #c0b596;
                    }
                }
                &:hover{
background-color: #f2f2f2;
                }
            }
        }
    
        &:hover{
            ul.subMenu1{
                -webkit-transform: scaleY(1);
                -ms-transform: scaleY(1);
                -o-transform: scaleY(1);
                transform: scaleY(1);
            }
        }
    }
}

.preview-quotation-top-area{
    width: 100%;
    height: 70px;
    background-color: #BD9B3E;  
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.preview-quotation-top-area-left-Area{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.preview-quotation-top-area-left-Area-box{
    background-color: #CBBC99;
    width: 100px;
    height: 70px;
  
}
.preview-quotation-top-area-left-Area-text{
    color: white;
    margin-left: 10px;
    font-size: 34px;
    font-weight: 600;
 
}
.preview-quotation-top-area-right-Area{
color: white;
font-size: 15px;
font-weight: 550;
margin-right: 30px;
}
/*  */