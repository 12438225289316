.accountant-panel-header{
    height: 80px;
    width:100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #353535;
    color:white;
    display:flex;
    justify-content:space-between;
    align-items:center;
    .accountant-panel-header-left{
        @media(max-width:7000px) and (min-width:600px){
            img{
                width:260px;
                z-index: 100000;
            }
        }
        @media(max-width:600px) and (min-width:0px){
            img{
                width:180px;
                z-index: 100000;
            }
        }
      
    }

    @media(max-width:7000px) and (min-width:600px){
        .accountant-panel-header-middle{/* 
background-color: white !important; */
        }
        .accountant-panel-header-rightt{
            display:flex;
    justify-content: center;
            width:30%;
            img{
    width:30px;
    height: 30px;
    margin-right:-15px;
    z-index: 1;
            }
        }
    }
    @media(max-width:600px) and (min-width:0px){
        .accountant-panel-header-middle{
  display:none;
        }
        .accountant-panel-header-rightt{
            display:flex;
            justify-content: center;
            width:70%;
            img{
    width:30px;
    height: 30px;
    margin-right:-20px;
    z-index: 1;
            }
        }
    }
  
}